<template>
  <div class="container">
    <div class="row text-center my-5" v-if="loading">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div v-else>
      <div class="row mb-3">
        <div class="col-md-8 mx-auto">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <h5 class="mb-2">My Online Programmes</h5>
              <div v-if="myProgrammeSchedules.length === 0">
                <div class="card bg-light mb-4">
                  <div class="card-body text-center">
                    <i class="fad fa-info-circle fa-3x mb-3 text-primary"></i>
                    <p>You have not yet subscribed to any online programs. Browse the list of upcoming programmes on offer by your practitioner below.</p>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row mb-3">
                  <div class="col-md-4 mb-3" v-for="schedule in myProgrammeSchedules">
                    <div class="card h-100 cursor-pointer" @click="viewSchedule(schedule.online_programme_schedule_id)">
                      <img v-if="schedule.online_programme.cover_image"
                           :src="schedule.online_programme.cover_image"
                           class="card-img-top mx-auto"
                           style="height: auto; width: 100%">
                      <img v-else
                           :src="user.clinic.logo"
                           class="card-img-top mx-auto p-4"
                           style="height: auto; width: 100%">
                      <div class="card-body text-center">
                        <h5>{{schedule.online_programme.title}}</h5>
                        <h5>{{schedule.start_date | formatDate}}</h5>
                        <p class="badge badge-success">Subscribed</p>
                        <p class="badge text-capitalize"
                           :class="schedule.payment_status === 'paid' ? 'badge-success' : 'badge-warning'">
                          {{schedule.payment_status | formatStatus}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <h5 class="mb-2">Upcoming Online Programmes</h5>
              <div v-if="practitionerProgrammeSchedules.length === 0">
                <div class="card bg-light">
                  <div class="card-body text-center">
                    <i class="fad fa-info-circle fa-3x text-primary mb-3"></i>
                    <p>Your practitioner does not have any upcoming Online Programmes on offer.</p>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row mb-3">
                  <div class="col-md-4 mb-3"
                       v-for="schedule in practitionerProgrammeSchedules"
                  >
                    <div class="card h-100">
                      <img v-if="schedule.online_programme.cover_image"
                           :src="schedule.online_programme.cover_image"
                           class="card-img-top mx-auto"
                           style="height: auto; width: 100%">
                      <img v-else
                           :src="user.clinic.logo"
                           class="card-img-top mx-auto p-4"
                           style="height: auto; width: 100%">
                      <div class="card-body text-center">
                        <h5>{{schedule.title}}</h5>
                        <h5>{{schedule.start_date | formatDate}}</h5>
                        <p>£{{schedule.price.toFixed(2)}}</p>
                        <button class="btn btn-primary" @click="viewSchedule(schedule.id)">
                          View
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      loading: true,
      myProgrammeSchedules: [],
      practitionerProgrammeSchedules: [],

    };
  },
  computed: {
    user() {
      return this.$store.user;
    }
  },
  methods: {
    fetchClientSchedules() {
      this.$axios.post(process.env.VUE_APP_API_URL + "/client/online-programmes/fetch-client-programmes", { client_id: this.user.id }).then(({ data }) => {
        this.myProgrammeSchedules = data;
        this.loading = false;
      });
    },
    fetchPractitionerSchedules() {
      this.$axios.post(process.env.VUE_APP_API_URL + "/client/online-programmes/fetch-practitioner-programmes", { client_id: this.user.id }).then(({ data }) => {
        this.practitionerProgrammeSchedules = data.filter((schedule) => {
          return (!schedule.subscribed_client_ids.includes(this.user.id) && schedule.is_client_booking_available)
        });
        this.loading = false;
      });
    },
    viewSchedule(id) {
      this.$router.push('/online-programmes/' + id);
    },
  },
  mounted() {
    this.fetchClientSchedules();
    this.fetchPractitionerSchedules();
  },
  filters: {
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
    formatDate(date) {
      return moment(date).format("LL [@] HH:mm A");
    },
  }
};
</script>

<style>
</style>
